import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import styled from 'styled-components';
import "typeface-gfs-didot";
import Typing from 'react-typing-animation';


export default () => {
  return (
    <Layout>
      <SEO title="TOP"/>
        <MainVisual>
          <Headline>
              <Typing speed={120}>Hello.</Typing>
          </Headline>
          <SubHeadline><SubHeadlineSpan></SubHeadlineSpan>Select Contents</SubHeadline>
        </MainVisual>
    </Layout>
  )
}

const MainVisual = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 94vh;

  @media (max-width: 768px) {
    height: 78vh;
  }
`

const Headline = styled.h1`
  font-size: 3rem;
  font-weight: 300;
  letter-spacing: 3px;
`

const SubHeadline = styled.p`
    position: relative;
    padding-top: 70px;
    font-size: 1rem;
    font-weight: 100;
    text-decoration: none;
    color: #000;
    bottom: -10vw;

    @media (max-width: 768px) {
      bottom: -40vw;
    }
`

const SubHeadlineSpan = styled.span`
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 24px;
    height: 24px;
    margin-left: -12px;
    border-left: 1px solid #000;
    border-bottom: 1px solid #000;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -webkit-animation: sdb 1.5s infinite;
    animation: sdb 1.5s infinite;
    box-sizing: border-box;
        @-webkit-keyframes sdb {
            0% {
            -webkit-transform: rotate(-45deg) translate(0, 0);
            opacity: 0;
            }
            50% {
            opacity: 1;
            }
            100% {
            -webkit-transform: rotate(-45deg) translate(-20px, 20px);
            opacity: 0;
            }
        }
        @keyframes sdb {
            0% {
            transform: rotate(-45deg) translate(0, 0);
            opacity: 0;
            }
            50% {
            opacity: 1;
            }
            100% {
            transform: rotate(-45deg) translate(-20px, 20px);
            opacity: 0;
            }
        }
`